.ant-menu {
  border-inline-end: none !important;
}

.ant-table-row-expand-icon {
  float: right !important;
}

.ant-tree-list-holder-inner {
  align-items: unset !important;
}

.checkbox-white .ant-checkbox-checked .ant-checkbox-inner {
  background-color: white !important;
}

.checkbox-white .ant-checkbox-checked .ant-checkbox-inner:after {
  border-color: black !important;
}

.ant-modal-body {
  text-align: right;
}

.ant-modal-close {
  top: 10px !important;
  inset-inline-end: 5px !important;
}

.ant-tabs-ink-bar {
  height: 12px !important;
  border-radius: 20px 20px;
  transform: translateX(45%) !important;
}

.ant-tabs-nav-list {
  justify-content: space-between;
  width: 100%;
}

.ant-select-selection-placeholder {
  text-align: right;
}

.ant-select-selector input {
  font-size: 18px;
}

.ant-modal-confirm-paragraph {
  max-width: 100% !important;
}

.ant-drawer-content-wrapper {
  box-shadow: none !important;
}

.centered-content-modal .ant-modal-confirm-title,
.centered-content-modal .ant-modal-confirm-btns {
  text-align: center;
}

h1.ant-typography,
h2.ant-typography,
h3.ant-typography,
h4.ant-typography,
h5.ant-typography {
  margin-top: 0;
}

@media (max-width: 768px) {
  h2.ant-typography {
    font-size: 28px;
  }

  .ant-tabs-tab {
    margin: 0 !important;
  }

  .ant-tabs-top > .ant-tabs-nav {
    margin: 0 !important;
  }

  .ant-select-clear {
    height: 20px !important;
    width: 20px !important;
    pointer-events: auto;
  }
}
