@import './antd-overrides.css';
@import './backgrounds.css';

@font-face {
  font-family: 'Blender Sport';
  src: url('../assets/fonts/Blender_Sport_BIGCAPS-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Blender Sport';
  src: url('../assets/fonts/Blender_Sport_BIGCAPS-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Blender Sport';
  src: url('../assets/fonts/Blender_Sport_BIGCAPS-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Blender Sport';
  src: url('../assets/fonts/Blender_Sport_SmallCAPS-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-variant: small-caps;
}

@font-face {
  font-family: 'Blender Sport';
  src: url('../assets/fonts/Blender_Sport_SmallCAPS-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-variant: small-caps;
}

@font-face {
  font-family: 'Blender Sport';
  src: url('../assets/fonts/Blender_Sport_SmallCAPS-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-variant: small-caps;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Blender Sport', sans-serif;
}

.ltr {
  direction: ltr;
}

.min-height-100vh {
  min-height: 100vh;
}

.page-vertical-padding {
  padding-top: 48px;
  padding-bottom: 48px;
}

.nav-link {
  color: initial;
  cursor: pointer;
}

.nav-link:hover {
  color: #014cad;
}

.icon-button-svg {
  cursor: pointer;
}

.icon-button-svg-active circle {
  fill: #014cad;
}

.icon-button-svg-active path {
  fill: white;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-card {
  margin: 28px 12px 28px 12px;
}

.placeholder-rtl::placeholder {
  text-align: right;
}

.icon-box {
  height: 90px;
  width: 90px;
}

.icon-box img {
  width: 50px;
}

@media (max-width: 768px) {
  .content-card {
    margin-top: 12px;
    margin-bottom: 12px;
  }

  .icon-box {
    height: 40px;
    width: 40px;
  }

  .icon-box img {
    width: 20px;
  }

  .icon-box-text {
    font-size: 10px;
  }
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.my-teams-header {
  gap: 20px;

  @media (max-width: 800px) and (min-width: 768px) {
    gap: 0;
  }
}

@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

@media (max-width: 850px) {
  .hidden-md {
    display: none !important;
  }
}
