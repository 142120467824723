.bg {
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll;
  min-height: calc(100vh - 64px);
  /* padding-bottom: 24px; */
}

.bg1 {
  background-image: url('../../public/images/backgrounds/bg1.png');
}

.bg2 {
  background-image: url('../../public/images/backgrounds/bg2.png');
}

.bg3 {
  background-image: url('../../public/images/backgrounds/bg3.png');
}

.bg4 {
  background-image: url('../../public/images/backgrounds/bg4.png');
  background-repeat: repeat;
}

.bg5 {
  background-image: url('../../public/images/backgrounds/bg5.png');
}

.bg6 {
  background-image: url('../../public/images/backgrounds/bg6.png');
}

.bg7 {
  background-image: url('../../public/images/backgrounds/bg7.png');
}

.bg8 {
  background-image: url('../../public/images/backgrounds/bg8.png');
  background-position: center;
}

.bg9 {
  background-image: url('../../public/images/backgrounds/bg9.png');
  background-position: center;
}

.bg-pizzahut-cover {
  background-color: black;
  min-height: calc(100vh - 64px);
}

.bg-pizza {
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('../../public/images/backgrounds/bg-pizzahut.png');
  background-position: center;
}

.bg-champions-lobby {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('../../public/images/backgrounds/champions-lobby.png');
  background-position: center;
  height: 31vh;
  min-height: calc(31vh - 64px);
}
.bg-champions-lobby-footer {
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('../../public/images/backgrounds/champions-lobby-footer.png');
  background-position: center;
  height: 360px;
}

.bg-champions-games-body {
  background-repeat: no-repeat;
  background-size: auto;
  background-color: white;
  background-image: url('../../public/images/backgrounds/champions-cc-logo-left.png');
  background-position: bottom left;
  min-height: calc(100vh - 64px);
}

.bg-champions-live {
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('../../public/images/backgrounds/champions-live.png');
  background-position: center;
}

.bg-champions-payment {
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('../../public/images/backgrounds/champions-payment.png');
  background-position: center;
  height: 215px;
}

.bg-champions-payment-success {
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('../../public/images/backgrounds/champions-payment-success.png');
  background-position: center;
}

.bg-champions-payment-fail {
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('../../public/images/backgrounds/champions-payment-fail.png');
  background-position: center;
}

.bg-champions-cc-cover {
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('../../public/images/backgrounds/champions-cc-cover.png');
  background-position: center;
}

.bg-champions-cc-cover {
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('../../public/images/backgrounds/champions-cc-cover.png');
  background-position: center;
}

.bg-pizzahut-cover-mobile {
  background-image: url('../../public/images/backgrounds/bg-pizzahut-text-mobile.png');
  background-size: cover;
  border: none;
  min-height: calc(100vh - 64px);
}

.bg-pizza-mobile {
  background-image: url('../../public/images/backgrounds/bg-pizzahut-mobile.png');
  background-size: contain;
  background-repeat: no-repeat;
  border: none;
  padding: 0px 30px;
}

.bgolympics-div {
  height: 105px;
  align-items: end;
  background-color: #ffffff;
}

@media only screen and (max-width: 1248px) {
  .bg1 {
    background-size: contain;
  }

  .bg2 {
    background-size: contain;
  }

  .bg3 {
    background-size: contain;
  }

  .bg4 {
    background-size: contain;
  }

  .bg5 {
    background-size: contain;
  }

  .bg6 {
    background-size: contain;
  }

  .bg7 {
    background-size: contain;
  }

  .bg9 {
    background-size: contain;
  }
}

@media only screen and (max-width: 700px) {
  .bg1 {
    background-image: url('../../public/images/backgrounds/bg1-mobile.png');
  }

  .bg2 {
    background-image: url('../../public/images/backgrounds/bg2-mobile.png');
  }

  .bg3 {
    background-image: url('../../public/images/backgrounds/bg3-mobile.png');
  }

  .bg4 {
    background-image: none;
  }

  .bg5 {
    background-image: url('../../public/images/backgrounds/bg5-mobile.png');
  }

  .bg6 {
    background-image: url('../../public/images/backgrounds/bg6-mobile.png');
  }

  .bg7 {
    background-image: url('../../public/images/backgrounds/bg7-mobile.png');
  }

  .bgolympics-div {
    height: 80px;
    align-items: end;
  }
}
