.menu-item {
  display: flex;
  align-items: center;
  position: relative;
  height: 100%;
  z-index: 0;
}

.item-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: blue;
  border-radius: 4px;
  z-index: -2;
  width: 70%;
  height: 20px;
  margin-right: 45px;
}

.text {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  border-radius: 4px;
}

.menu-item .icon-wrapper {
  margin-left: 8px;
  display: flex;
  align-items: center;
  z-index: 2;
}

.menu-item.active .icon-wrapper::after {
  content: '';
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left: 100%;
  background-color: blue;
  border-radius: 4px;
}

.icon-text-wrapper {
  display: flex;
  align-items: center;
}

.menu-item .text {
  color: black;
}

.side-menu {
  overflow-x: hidden;
  overflow-y: hidden;
}
